import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AppContext from "../component/AppContext";
import { hideNavBar } from "../component/Common";
import { InputText } from "../component/Forms";
import { getUserBio, supabase } from "../data/backend";
import { logo } from "../data/data";

const Login = () => {
  const [, setContext] = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    hideNavBar();
  }, []);

  async function doLogin() {
    const username = (document.getElementById("username") as HTMLInputElement)
      .value;
    const password = (document.getElementById("password") as HTMLInputElement)
      .value;

    if (password && username) {
      const toastID = toast.loading("Proses Login ...");
      let { error } = await supabase.auth.signIn({
        email: username,
        password: password,
      });
      if (error) {
        toast.update(toastID, {
          render: error.message,
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
        console.log(error);
      } else {
        const userData = await getUserBio();
        setContext({
          title: "Home",
          user: {
            fullName: userData.fullName,
            kelas: userData.kelas,
            foto: userData.foto,
            email: userData.email,
          },
        });
        toast.update(toastID, {
          render: "Berhasil",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });

        navigate("/");
      }
    } else {
      toast.error("Email / Password tidak boleh kosong");
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div
          className="col d-flex align-items-center justify-content-center"
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <div className="col-12" style={{ maxWidth: 350 }}>
            <div className="text-center">
              <h4>Login</h4>
              <p>Masukkan Email dan Password untuk melanjutkan</p>
            </div>
            <div className="card">
              <div className="car-body pt-4 px-5">
                <div className="text-center">
                  <img alt="logo" src={logo} style={{ width: 150 }} />
                </div>
                <InputText
                  id="username"
                  placeholder="Email"
                  icon="envelope-fill"
                />
                <InputText
                  id="password"
                  placeholder="Password"
                  type="password"
                  icon="key-fill"
                />
                <div className="d-grid mt-3 gap-2">
                  <button className="btn btn-primary" onClick={doLogin}>
                    LOGIN
                  </button>
                  <p className="text-center">
                    Belum Punya Akun ?
                    <br />
                    <Link to={"/signup"}>Daftar Baru</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
