import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../data/backend";

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    supabase.auth.signOut();
    navigate("/login");
  }, [navigate]);
  return <p className="text-center text-secondary">Proses Logout ...</p>;
};

export default Logout;
