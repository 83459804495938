import { useContext, useEffect } from "react";
import { useNavigate, Route, Routes, useLocation } from "react-router-dom";
import AppContext from "../component/AppContext";
import Biodata from "../pages/Biodata";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Signup from "../pages/Signup";
import { getUserBio, supabase } from "./backend";

const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);

  useEffect(() => {
    (async () => {
      if (location.pathname === "/login" || location.pathname === "/signup")
        return;

      // check auth
      const user = supabase.auth.user();
      // authed && setcontext
      if (user) {
        let c = JSON.parse(JSON.stringify(context));
        const userData = await getUserBio();
        c.user = {
          email: userData.email,
          foto: userData.foto,
          fullName: userData.fullName,
          kelas: userData.kelas,
        };
        setContext(c);
        return;
      }

      // not authed redirect to login
      navigate("/login");
    })();
  }, [location, context, navigate, setContext]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/biodata" element={<Biodata />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
};

export default AppRoutes;
