import { iconList } from "../component/ComponentTypes";

type menuType = {
  name: string;
  link: string;
  icon: iconList;
}[];

const menu: menuType = [
  {
    name: "Home",
    link: "#/",
    icon: "house-door",
  },
  {
    name: "Biodata",
    link: "#/biodata",
    icon: "person-bounding-box",
  },
  {
    name: "Logout",
    link: "#/logout",
    icon: "arrow-left-square",
  },
];
export default menu;
