import IMask from "imask";
import { CSSProperties, useEffect } from "react";
import { iconList } from "./ComponentTypes";

function slugify(text: string) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "_") // Replace spaces with _
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

function InputText(props: {
  label?: string;
  labelShow?: boolean;
  placeholder?: string;
  value?: string;
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
  type?: "text" | "password";
  status?: "" | "valid" | "warning" | "invalid";
  icon?: iconList;
  iconPosition?: "start" | "end";
}) {
  return (
    <>
      <div className="form-group">
        {props.labelShow === false ? "" : <label>{props.label}</label>}
        <div className="input-group">
          {props.icon || (props.icon && props.iconPosition === "start") ? (
            <span className="input-group-text">
              <i className={`bi-${props.icon}`}></i>
            </span>
          ) : (
            ""
          )}
          <input
            name={props.id || slugify(props.label || "")}
            id={props.id || slugify(props.label || "")}
            type={props.type || "text"}
            className={`form-control is-${props.status || ""}`}
            placeholder={props.placeholder}
            defaultValue={props.value}
            readOnly={props.readOnly || false}
            disabled={props.disabled || false}
          />
          {props.icon && props.iconPosition === "end" ? (
            <span className="input-group-text">
              <i className={`bi-${props.icon}`}></i>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

function Textarea(props: {
  label?: string;
  rows?: number;
  placeholder?: string;
  value?: string;
  id?: string;
  disabled?: boolean;
}) {
  useEffect(() => {
    (
      document.querySelector(
        `#${props.id || slugify(props.label || "")}`
      ) as HTMLInputElement
    ).value = props.value || "";
  }, [props.value, props.id, props.label]);

  return (
    <>
      <div className="form-group">
        <label>{props.label}</label>
        <textarea
          name={props.id || slugify(props.label || "")}
          id={props.id || slugify(props.label || "")}
          className="form-control"
          rows={props.rows || 3}
          placeholder={props.placeholder}
          disabled={props.disabled || false}
          defaultValue={props.value}
        ></textarea>
      </div>
    </>
  );
}

function InputDate(props: { label?: string; value?: string; id?: string }) {
  useEffect(() => {
    const id = props.id || slugify(props.label || "");
    const element = document.getElementById(id) as HTMLInputElement;
    if (element) {
      IMask(element, {
        mask: "DD/MM/YYYY",
        lazy: false,
        blocks: {
          YYYY: {
            mask: "0000",
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
          DD: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
          },
        },
      });
      if (props.value) {
        element.value = props.value;
      }
    }
  }, [props.value, props.id, props.label]);

  return (
    <div className="form-group">
      <label>{props.label}</label>
      <div className="input-group">
        <span className="input-group-text" id="basic-addon1">
          <i className="bi-calendar3"></i>
        </span>
        <input
          name={props.id || slugify(props.label || "")}
          id={props.id || slugify(props.label || "")}
          type="text"
          defaultValue={props.value}
          className="form-control"
        />
      </div>
    </div>
  );
}

function InputNumber(props: {
  label?: string;
  value?: string;
  length?: number;
  id?: string;
  icon?: iconList;
  readOnly?: boolean;
}) {
  useEffect(() => {
    const id = props.id || slugify(props.label || "");
    const element = document.getElementById(id) as HTMLInputElement;
    if (element) {
      if (props.length) {
        let lengthMask: string = "";
        for (let i = 0; i < props.length; i++) {
          lengthMask += "0";
        }
        IMask(element, {
          mask: lengthMask,
          lazy: false,
        });
      } else {
        IMask(element, { mask: IMask.MaskedNumber });
      }
      if (props.value) {
        element.value = props.value;
      }
    }
  }, [props.value, props.label, props.id, props.length]);

  return (
    <div className="form-group">
      <label>{props.label}</label>
      <div className="input-group">
        {props.icon ? (
          <span className="input-group-text" id="basic-addon1">
            <i className={`bi-${props.icon}`}></i>
          </span>
        ) : (
          <></>
        )}
        <input
          id={props.id || slugify(props.label || "")}
          name={props.id || slugify(props.label || "")}
          type="text"
          defaultValue={props.value}
          className="form-control"
          readOnly={props.readOnly || false}
        />
      </div>
    </div>
  );
}

function InputNomorHP(props: { label?: string; value?: string; id?: string }) {
  useEffect(() => {
    const id = props.id || slugify(props.label || "");
    const element = document.getElementById(id) as HTMLInputElement;
    if (element) {
      IMask(element, {
        mask: "+{62}000-0000-00000",
        lazy: true,
      });
    }
    if (props.value) {
      element.value = props.value;
    }
  }, [props.value, props.id, props.label]);

  return (
    <div className="form-group">
      <label>{props.label}</label>
      <div className="input-group">
        <span className="input-group-text">
          <i className="bi-phone"></i>
        </span>
        <input
          id={props.id || slugify(props.label || "")}
          name={props.id || slugify(props.label || "")}
          type="text"
          defaultValue={props.value}
          className="form-control"
          data-inputmask-inputformat=""
          data-inputmask="'mask' : '628-999-999-999[9]'"
          data-mask=""
          inputMode="numeric"
        />
      </div>
    </div>
  );
}

function InputEmail(props: { label?: string; value?: string; id?: string }) {
  useEffect(() => {
    const id = props.id || slugify(props.label || "");
    const element = document.getElementById(id) as HTMLInputElement;
    if (props.value) {
      element.value = props.value;
    }
  }, [props.value, props.id, props.label]);

  return (
    <div className="form-group">
      <label>{props.label}</label>
      <div className="input-group">
        <span className="input-group-text">
          <i className="bi-envelope"></i>
        </span>
        <input
          id={props.id || slugify(props.label || "")}
          name={props.id || slugify(props.label || "")}
          type="text"
          defaultValue={props.value}
          className="form-control"
        />
      </div>
    </div>
  );
}

function InputNomorWA(props: { label?: string; value?: string; id?: string }) {
  useEffect(() => {
    const id = props.id || slugify(props.label || "");
    const element = document.getElementById(id) as HTMLInputElement;
    if (element) {
      IMask(element, {
        mask: "+{62}000-0000-00000",
        lazy: true,
      });
    }
    if (props.value) {
      element.value = props.value;
    }
  }, [props.value, props.id, props.label]);

  return (
    <div className="form-group">
      <label>{props.label}</label>
      <div className="input-group">
        <span className="input-group-text">
          <i className="bi-whatsapp"></i>
        </span>
        <input
          id={props.id || slugify(props.label || "")}
          name={props.id || slugify(props.label || "")}
          type="text"
          defaultValue={props.value}
          className="form-control"
          inputMode="numeric"
        />
      </div>
    </div>
  );
}

function Select(props: {
  label?: string;
  id?: string;
  value?: string;
  icon?: iconList;
  style?: CSSProperties;
  inlineLabel?: boolean;
  options?: {
    text: string;
    value: string;
  }[];
}) {
  useEffect(() => {
    const id = props.id || slugify(props.label || "");
    const element = document.getElementById(id) as HTMLInputElement;

    if (props.value) {
      element.value = props.value;
    }
  }, [props.id, props.value, props.label]);

  return (
    <div
      className="form-group"
      style={{ ...props.style, position: "relative" }}
    >
      {props.label ? (
        <label
          style={
            props.inlineLabel
              ? {
                  ...{
                    left: props.icon ? 48 : 8,
                    position: "absolute",
                    zIndex: 4,
                    top: -8,
                    background: "#fff",
                    color: "#757575",
                    fontSize: ".8em",
                    paddingLeft: 5,
                    paddingRight: 5,
                  },
                }
              : {}
          }
        >
          {props.label}
        </label>
      ) : (
        <></>
      )}

      <div className="input-group mb-3" style={{ height: "100%" }}>
        {props.icon ? (
          <label className="input-group-text" htmlFor={props.id || props.label}>
            <i className={`bi-${props.icon}`}></i>
          </label>
        ) : (
          <></>
        )}
        <select
          style={{ height: "100%" }}
          className="form-select"
          id={props.id || slugify(props.label || "")}
          name={props.id || slugify(props.label || "")}
        >
          {props.options &&
            props.options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.text}
                </option>
              );
            })}
        </select>
      </div>
    </div>
  );
}

export {
  slugify,
  InputText,
  Textarea,
  InputDate,
  InputNomorHP,
  InputNomorWA,
  InputNumber,
  Select,
  InputEmail,
};
