import Drawer from "./component/Drawer";
import Navbar from "./component/Navbar";
import { HashRouter as Router } from "react-router-dom";
import AppRoutes from "./data/AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContext, { AppContextInterface } from "./component/AppContext";
import { useState } from "react";

function App() {
  const [myContext, setMyContext] = useState<AppContextInterface>({
    title: "Home",
  });

  return (
    <>
      <AppContext.Provider value={[myContext, setMyContext]}>
        <ToastContainer />
        <Navbar />
        <Drawer />
        <div className="homeNavbar" style={{ height: "60px" }}></div>
        <div className="container-fluid">
          <div className="row">
            <Router>
              <AppRoutes />
            </Router>
            <div className="App"></div>
          </div>
        </div>
      </AppContext.Provider>
    </>
  );
}

export default App;
