import React, { createContext } from "react";

interface AppContextInterface {
  activeTab?: number;
  value?: string;
  title: string;
  user?: {
    fullName: string;
    kelas: string;
    foto: string;
    email: string;
  };
}

export type { AppContextInterface };

const def: AppContextInterface = { value: "", title: "" };

const AppContext = createContext<
  [
    AppContextInterface,
    React.Dispatch<React.SetStateAction<AppContextInterface>>
  ]
>([def, () => {}]);

export default AppContext;
