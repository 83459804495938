import { logo, schoolName } from "../data/data";
import menus from "../data/menu";

const Drawer = (props: any) => {
  const menuClick = (e: any) => {
    document.getElementById("closeCanvas")?.click();
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-start homeNavbar"
        id="offcanvasExample"
        style={{ maxWidth: 280 }}
      >
        <div className="offcanvas-header">
          <div className="text-center pt-3">
            <img src={logo} className="w-75" alt="logo" />
            <h3 className="mt-3">{schoolName}</h3>
          </div>
        </div>
        <div className="offcanvas-body">
          <button
            type="button"
            data-bs-dismiss="offcanvas"
            id="closeCanvas"
            className="d-none"
          ></button>
          <div className="list-group">
            {menus.map((menu, index) => {
              return (
                <a
                  id={`${`drawer-menu-${index}`}`}
                  onClick={menuClick}
                  key={index}
                  href={"/" + menu.link}
                  className={
                    window.location.href.substr(
                      window.location.href.indexOf("#")
                    ) === menu.link
                      ? `list-group-item list-group-item-action active`
                      : `list-group-item list-group-item-action`
                  }
                  aria-current="true"
                >
                  <i className={`bi-${menu.icon} me-3`}></i>
                  {menu.name}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Drawer;
