import { createClient } from "@supabase/supabase-js";

const backend = {
  supabaseUrl: "https://gjhlzmuqjhmxmlmqlxgr.supabase.co",
  supabaseKey:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdqaGx6bXVxamhteG1sbXFseGdyIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTk3ODUzMzMsImV4cCI6MTk3NTM2MTMzM30.lFc0VduU5OufjIHJr3TCbvSQqvYgywVXnZOSnOqaS8Q",
};

const supabase = createClient(backend.supabaseUrl, backend.supabaseKey);

const getUserBio = async () => {
  return new Promise<{
    fullName: string;
    kelas: string;
    foto: string;
    email: string;
  }>(async (resolve, reject) => {
    const user = supabase.auth.user();
    if (user) {
      const { data, error } = await supabase
        .from("biodata")
        .select("*")
        .eq("email", user.email);
      if (error) {
        reject(error);
      } else {
        if (data && data.length)
          resolve({
            fullName: data[0].namalengkap,
            kelas: data[0].kelas,
            foto: data[0].foto,
            email: user.email || "",
          });
      }
    } else {
      reject("Gagal Login");
    }
  });
};

export { supabase, getUserBio };
