const dates = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

function PapanAbsen(props: { absensi: string[] }) {
  return (
    <>
      <div className="card p-3">
        <div className="row">
          {dates.map((date, index) => (
            <div key={index} className="col-2 text-center">
              <p
                className={`m-0 text-${
                  props.absensi?.[index] === "Hadir"
                    ? "primary"
                    : props.absensi?.[index] === "Izin"
                    ? "success"
                    : props.absensi?.[index] === "Sakit"
                    ? "warning"
                    : props.absensi?.[index] === "Pulang"
                    ? "primary"
                    : "light"
                }`}
                style={{ fontSize: 30 }}
              >
                <i className="bi-check-square-fill"></i>
              </p>
              <p className="m-0">{date}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default PapanAbsen;
