const Navbar = () => {
  return (
    <>
      <nav className="navbar fixed-top navbar-light bg-white homeNavbar">
        <div className="navbar-brand ps-3 fw-bold">
          <button
            className="d-none"
            type="button"
            id="toggleCanvas"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
          ></button>
          <button
            className="d-none"
            type="button"
            id="dismissCanvas"
            data-bs-dismiss="offcanvas"
            data-bs-target="#offcanvasExample"
          ></button>
          <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button">
            <i className="bi-list pe-3 text-black"></i>
          </a>
          Home
        </div>
      </nav>
    </>
  );
};

export default Navbar;
