import { toast } from "react-toastify";
import { supabase } from "../data/backend";
import { schoolName } from "../data/data";

const absen = async (status: string) => {
  const user = supabase.auth.user();
  const toastID = toast.loading("Absen...");
  if (user) {
    const email = user?.email;
    const { error } = await supabase
      .from("absensi")
      .insert([{ email, status: status, sekolah: schoolName }]);
    if (error) {
      switch (error.code) {
        case "23505":
          toast.update(toastID, {
            render: `Anda sudah absen "${status}" hari ini`,
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
          break;

        default:
          break;
      }
    } else {
      toast.update(toastID, {
        render: `Absen "${status}" berhasil`,
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    }
  } else {
    toast.update(toastID, {
      render: `User belum login`,
      type: "error",
      isLoading: false,
      autoClose: 2000,
    });
  }
};

export { absen };
