import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { absen } from "../component/absen";
import AppContext from "../component/AppContext";
import { showNavBar } from "../component/Common";
import { Select } from "../component/Forms";
import PapanAbsen from "../component/PapanAbsen";
import { supabase } from "../data/backend";

const getCurrentMonth = () => {
  const date = new Date();
  return date.getMonth() + 1;
};

const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear();
};

const Home = () => {
  const [appContext, setAppContext] = useContext(AppContext);
  const [absenHadir, setAbsenHadir] = useState<string[]>([]);

  const handleRefresh = async (bulan: string) => {
    const user = supabase.auth.user();

    const toastID = toast.loading("Proses...");

    if (user) {
      let { data: absensi, error } = await supabase
        .from("absensi")
        .select("*")
        .gte("tanggal", `${getCurrentYear()}-${bulan}-01`)
        .lte("tanggal", `${getCurrentYear()}-${bulan}-31`);

      if (error) {
        toast.update(toastID, {
          render: "Terjadi Kesalahan ...",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      } else {
        let parsedAbsen: string[] = [];
        absensi &&
          absensi.length &&
          absensi.forEach((dataAbsen) => {
            // extract date from string
            const date = dataAbsen.tanggal.split("-");
            const tgl = date[2];
            parsedAbsen[parseInt(tgl) - 1] = dataAbsen.status;
          });
        console.log(parsedAbsen);
        setAbsenHadir(parsedAbsen);
        toast.update(toastID, {
          render: "Berhasil",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  useEffect(() => {
    if (appContext.title !== "Home") {
      const c = JSON.parse(JSON.stringify(appContext));
      c.title = "Home";
      setAppContext(c);
    }
  }, [appContext, setAppContext]);

  useEffect(() => {
    showNavBar();
    let month: string | number = getCurrentMonth();
    month = month < 10 ? "0" + month : month;
    (document.getElementById("bulan") as HTMLInputElement).value =
      month.toString();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="d-grid gap-3 mt-3">
            <div className="card p-3">
              <div className="row">
                <div className="col-auto">
                  <div
                    className="bg-primary"
                    style={{ width: 125, height: 125 }}
                  ></div>
                </div>
                <div className="col">
                  <h4>{appContext.user?.fullName}</h4>
                  <p className="text-secondary">{appContext.user?.kelas}</p>
                </div>
              </div>
            </div>
            <div className="card p-3">
              <div className="row mb-3">
                <div className="col">
                  <Select
                    label="Status"
                    icon="calendar-check"
                    inlineLabel={true}
                    style={{ height: "100%" }}
                    options={[
                      {
                        text: "Hadir",
                        value: "Hadir",
                      },
                      {
                        text: "Sakit",
                        value: "Sakit",
                      },
                      {
                        text: "Izin",
                        value: "Izin",
                      },
                      {
                        text: "Pulang",
                        value: "Pulang",
                      },
                    ]}
                  />
                </div>
                <div className="col">
                  <div className="d-grid">
                    <button
                      onClick={() => {
                        const status = (
                          document.getElementById("status") as HTMLInputElement
                        ).value;
                        absen(status);
                      }}
                      className="btn btn-success py-3"
                    >
                      ABSEN
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Select
                    label="Bulan"
                    inlineLabel={true}
                    icon="calendar-month"
                    style={{ height: "100%" }}
                    options={[
                      {
                        text: "Januari",
                        value: "01",
                      },
                      {
                        text: "Februari",
                        value: "02",
                      },
                      {
                        text: "Maret",
                        value: "03",
                      },
                      {
                        text: "April",
                        value: "04",
                      },
                      {
                        text: "Mei",
                        value: "05",
                      },
                      {
                        text: "Juni",
                        value: "06",
                      },
                      {
                        text: "Juli",
                        value: "07",
                      },
                      {
                        text: "Agustus",
                        value: "08",
                      },
                      {
                        text: "September",
                        value: "09",
                      },
                      {
                        text: "Oktober",
                        value: "10",
                      },
                      {
                        text: "November",
                        value: "11",
                      },
                      {
                        text: "Desember",
                        value: "12",
                      },
                    ]}
                  />
                </div>
                <div className="col">
                  <div className="d-grid">
                    <button
                      onClick={() => {
                        const bulan = (
                          document.getElementById("bulan") as HTMLInputElement
                        ).value;
                        handleRefresh(bulan);
                      }}
                      className="btn btn-primary py-3"
                    >
                      CEK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-3">
              <h4>KEHADIRAN</h4>
              <PapanAbsen absensi={absenHadir} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
