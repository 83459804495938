function hideNavBar() {
  const navbar = document.getElementsByClassName("homeNavbar");
  for (let i = 0; i < navbar.length; i++) {
    const element = navbar.item(i);
    element?.classList.add("d-none");
  }
}

function showNavBar() {
  const navbar = document.getElementsByClassName("homeNavbar");
  for (let i = 0; i < navbar.length; i++) {
    const element = navbar.item(i);
    element?.classList.remove("d-none");
  }
}

export { hideNavBar, showNavBar };
