import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { hideNavBar } from "../component/Common";
import { InputText } from "../component/Forms";
import { logo } from "../data/data";
import { supabase } from "../data/backend";

const Signup = () => {
  const navigate = useNavigate();

  useEffect(() => {
    hideNavBar();
  }, []);

  async function doSignup() {
    const username = (document.getElementById("username") as HTMLInputElement)
      .value;
    const password = (document.getElementById("password") as HTMLInputElement)
      .value;
    const password2 = (document.getElementById("password2") as HTMLInputElement)
      .value;

    if (password !== password2) {
      toast("Password tidak sama", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }

    // check if string consists of at least 1 letter and number
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])/;

    if (!regex.test(password)) {
      toast("Password harus campuran angka dan huruf", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }

    if (username && password) {
      const toastID = toast.loading("Proses Mendaftar...");
      let { user, error } = await supabase.auth.signUp({
        email: username,
        password: password,
      });
      if (error) {
        toast.update(toastID, {
          render: error.message,
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });

        console.log(error);
      } else {
        console.log(user);
        toast.update(toastID, {
          render:
            "Pendaftaran Berhasil, Silakan Login Menggunakan Account yang baru",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
        navigate("/login");
      }
    } else {
      toast("Masih ada field yang kosong", {
        type: "error",
        autoClose: 2000,
      });
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div
          className="col d-flex align-items-center justify-content-center"
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <div className="col-12" style={{ maxWidth: 350 }}>
            <div className="text-center">
              <h4>Daftar</h4>
              <p>Masukkan Email dan Password untuk mendaftar</p>
            </div>
            <div className="card">
              <div className="car-body pt-4 px-5">
                <div className="text-center">
                  <img alt="logo" src={logo} style={{ width: 150 }} />
                </div>
                <InputText
                  id="username"
                  placeholder="Email"
                  icon="envelope-fill"
                />
                <InputText
                  id="password"
                  placeholder="Password"
                  type="password"
                  icon="key-fill"
                />
                <InputText
                  id="password2"
                  placeholder="Password Lagi"
                  type="password"
                  icon="key-fill"
                />
                <div className="d-grid mt-3 gap-2 mb-3">
                  <button onClick={doSignup} className="btn btn-danger">
                    DAFTAR
                  </button>
                  <p className="text-center">
                    <Link id="backToLogin" to={"/login"}>
                      Kembali ke Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
